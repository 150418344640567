import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import NavBar from '../components/NavBar';
import Contact from '../components/Contact';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '../components/Link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faHome } from '@fortawesome/free-solid-svg-icons';

import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 75,
  },
  titleBorder: {
    borderLeft: '1px solid #bf563d',
    paddingLeft: 10,
  },
  subtitleBorder: {
    borderBottom: '1px solid #bf563d',
    paddingBottom: 10,
  },
  stripes: {
    color: theme.palette.background.default,
    paddingTop: theme.spacing(2),
    margin: 0,
    paddingBottom: theme.spacing(2),
    backgroundImage: `linear-gradient(to bottom right, #FFF 50%, ${theme.palette.background.default} 50%)`,
    zIndex: -10,
    marginBottom: theme.spacing(1),
  },
  stripesLeft: {
    padding: 0,
    margin: 0,
    height: '25vh',
    opacity: '25%',
    backgroundImage:
      'repeating-linear-gradient(45deg, #336666 0, #336666 3px, transparent 3px, transparent 30px)',
    transform: 'skewY(5deg)',
  },
  stripesRight: {
    padding: 0,
    margin: 0,
    height: '15vh',
    opacity: '25%',
    backgroundImage:
      'repeating-linear-gradient(45deg, #336666 0, #336666 3px, transparent 3px, transparent 30px)',
    transform: 'skewY(-5deg)',
  },
  media: {
    display: 'block',
    position: 'absolute',
    bottom: '-40%',
    left: 0,
    right: 0,
  },
  marginRight: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  marginLeft: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  align: {
    textAlign: 'center',
    margin: theme.spacing(1),
  },
  link: {
    color: '#1f3536',
    textDecoration: 'none',
  },
  projectNum: {
    paddingTop: theme.spacing(2),
    fontFamily: 'Roboto Condensed',
    fontKerning: 5,
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontWeight: 500,
    paddingBottom: theme.spacing(2),
  },
}));

export default function Pickles() {
  const data = useStaticQuery(graphql`
    query {
      pickles: file(relativePath: { eq: "Pickles.png" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid(maxHeight: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const classes = useStyles();

  return (
    <>
      <Container maxWidth="xl">
        <Box my={5}>
          <NavBar />
        </Box>
      </Container>
      <Box
        position="relative"
        m={0}
        p={0}
        maxWidth="100vw"
        maxHeight="75vh"
        className={classes.stripes}
      >
        <Box
          display="flex"
          justifyContent="space-evenly"
          flexDirection="column"
          alignItems="center"
        >
          <Box>
            <Typography variant="h1" className={classes.projectNum}>
              GRACED PICKLES
            </Typography>
          </Box>
          <Container maxWidth="md">
            <Img
              alt="graced pickles website screenshot"
              fluid={data.pickles.childImageSharp.fluid}
            />
          </Container>
        </Box>
      </Box>
      <Box className={classes.root}>
        <Grid container spacing={6} justify="center" direction="row">
          <Grid item xs={12} md={4}>
            <Typography variant="h3" className={classes.align}>
              Overview
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} className={classes.titleBorder}>
            <Typography variant="h5" gutterBottom>
              Graced Pickles is a mock e-commerce website built over a 10-day
              sprint with a team of three developers.
            </Typography>
            <Typography variant="h5" gutterBottom>
              We created the application with a blank slate, starting out with
              important questions:
              <Box component="ul">
                <Box component="li">what product to sell?</Box>
                <Box component="li">how do we want to set up our database?</Box>
                <Box component="li">
                  what will the architecture of the site look like to help with
                  scaling?
                </Box>
              </Box>
              After we spent a day solving these issues on a whiteboard and
              divided up tasks using Github project boards, we coded features
              independently and utilized pair programming when needed.
            </Typography>
            <Typography variant="h5" gutterBottom>
              This project gave me the chance to improve my skills using the
              technologies we learned over our first 6 weeks at Fullstack. Since
              it was my first team experience and we all had a chance to be
              project managers in addition to developers, time management, quick
              conflict resolution and clear communication were valuable lessons
              learned.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.stripesLeft}></Box>
      <Box className={classes.root}>
        <Grid container spacing={6} justify="center" direction="row">
          <Grid item xs={12} md={4}>
            <Typography variant="h3" className={classes.align}>
              Tech Stack
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} className={classes.titleBorder}>
            <Box pb={2}>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.subtitleBorder}
              >
                Language & Environment
              </Typography>
              <Typography variant="h5" gutterBottom>
                JavaScript
              </Typography>
              <Typography variant="h5" gutterBottom>
                Node.js
              </Typography>
            </Box>
            <Box pb={2}>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.subtitleBorder}
              >
                Backend
              </Typography>
              <Typography variant="h5" gutterBottom>
                Express.js
              </Typography>
              <Typography variant="h5" gutterBottom>
                PostgreSQL
              </Typography>
              <Typography variant="h5" gutterBottom>
                Sequelize
              </Typography>
            </Box>
            <Box pb={2}>
              <Typography
                variant="h4"
                gutterBottom
                className={classes.subtitleBorder}
              >
                Frontend
              </Typography>
              <Typography variant="h5" gutterBottom>
                React.js
              </Typography>
              <Typography variant="h5" gutterBottom>
                Redux
              </Typography>
              <Typography variant="h5" gutterBottom>
                Bootstrap Framework
              </Typography>
              <Typography variant="h5" gutterBottom>
                Google OAuth
              </Typography>
              <Typography variant="h5" gutterBottom>
                Stripe
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Container maxWidth="sm">
        <Box display="flex" justifyContent="center">
          <Button
            className={classes.marginRight}
            variant="contained"
            color="secondary"
            size="large"
            href="http://gracedpickles.com"
            target="blank"
          >
            DEMO
          </Button>
          <Button
            className={classes.marginLeft}
            variant="outlined"
            size="large"
            color="secondary"
            href="https://github.com/jennmez/"
            target="blank"
          >
            GITHUB
          </Button>
        </Box>
      </Container>
      <Box className={classes.stripesRight}></Box>
      <Container maxWidth="lg">
        <Box mt={5}>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Typography variant="h4" className={classes.align}>
                <Link to="/weatherd" color="secondary">
                  <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
                  <Box component="span" p={2} className={classes.link}>
                    Weather'd
                  </Box>
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h4" className={classes.align}>
                <Link to="/" color="secondary">
                  <FontAwesomeIcon
                    icon={faHome}
                    className={classes.align}
                  ></FontAwesomeIcon>
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="h4" className={classes.align}></Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Box mt={5}>
        <Contact />
      </Box>
    </>
  );
}
